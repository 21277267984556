<template>
<div class="widget">
  <div class="top">
  <p>
    <img alt="8x9 logo" src="@/assets/spLogo.png" class="logo">
    <span>レッスン予約システム</span>
  </p>
  </div>
  <div class="user-header">
    <div class="left">
      <div class="user-info">
        <div class="horizontal name">
          <span class="nowrap">
            <font-awesome-icon icon="user-graduate" class="icon" />
            <span class="label">お名前：</span>
          </span>
          <span style="ellipsis-container">
            <span class="ellipsis">{{ name }}</span><span class="small">様</span>
          </span>
        </div>
        <div class="horizontal bookings">
          <span class="nowrap">
            <font-awesome-icon :icon="'flag'" class="icon" size="sm" />
            <span class="label">ご予約：</span>
          </span>
          <span class="nowrap">
            <em>{{ ticketsCount - ticketsAvailable }} / {{ ticketsCount }}</em>
          </span>
        </div>
      </div>
      <div class="user-info">
      </div>
    </div>
    <div class="right">
      <button class="button" v-on:click="onChangeUser">
        <font-awesome-icon :icon="'user-friends'" class="icon" />
        生徒切替
      </button>
    </div>
  </div>
</div>
</template>

<script>
import { ThenableMap } from '@/data/util';

export default {
  name: 'SpUserWidget',
  props: {
    student: Object,
    bookings: Array
  },
  computed: {
    name() {
      return this.student ? `${this.student.lastName} ${this.student.firstName}` : '--';
    },
    ticketsAvailable() {
      return this.student ? this.student.ticketsAvailable : 0;
    },
    ticketsCount() {
      return this.student ? this.student.ticketsCount : 0;
    },
    // bookingCount() {
    //   return this.bookings.length;
    // },
    // numTickets() {
    //   return Number(this.student?.ticketsCount ?? 0);
    // },
    // possibleBookingCount() {
    //   return this.student ? this.numTickets : 0;
    // },
    // hitMaxBookings() {
    //   return this.bookingCount == this.possibleBookingCount;
    // },
    // - Fields
    courseName() {
      return courseId => this.coursesById.find(courseId, it => it.name) ?? courseId;
    }
  },
  methods: {
    onBack: function() {
      this.$router.back();
    },
    onChangeUser() {
      this.$router.push({ name: 'SwitchUser', query: this.$route.query });
    }
  }
}
</script>

<style scoped lang="scss">
.top {
  p{
    margin: 0;
    padding-bottom: 5px;
  }
  .logo {
    margin: 0 auto;
    max-height: 65px;
    vertical-align: middle;
  }
  span{
    margin-left: 10px;
    font-size: 130%;
    vertical-align: middle;
  }
}

.widget {
  display: flex;
  flex-direction: column;
}

.button.spot {
  background-color: #70b94c !important;

  .icon {
    color: white !important;
  }
}

.user-header {
  border: 1px solid #00a6cf;
  border-radius: 5px;
  color: #333333;
  display: flex;
  font-size: 13px;
  padding: 7px 10px 7px 16px;

  .nowrap {
    white-space: nowrap;
  }

  .item {
    &:not(:last-child):after {
      content: ",";
      margin-right: 0.25em;
    }
  }

  .ellipsis-container {
    align-items: baseline;
    display: flex;
    min-width: 0;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 100;
  }

  .icon {
    margin-right: 3px;
    width: 16px;
  }

  .left {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    text-align: left;
    min-width: 0;

    .user-info {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      margin-right: 10px;
      min-width: auto;
      white-space: nowrap;

      .horizontal {
        display: flex;
        align-items: center;
        margin-right: 15px;
        overflow: hidden;

        &.wrap {
          flex-wrap: wrap;
        }
      }

      .small {
        font-size: 12px;
        margin-left: 3px;
      }
    }
  }

  .right {
    align-items: center;
    border-left: 1px solid #cccccc;
    display: flex;
    // padding: 10px 0 10px 10px;
    margin-left: -15px;
    padding: 0 0 0 10px;
  }

  span {
    color: #333333;

    em {
      color: #333333;
      font-style: normal;
    }
  }

  .name .icon {
    color: #00a6cf;
  }

  .bookings .icon {
    color: #ff8b50;
  }

  .spot .icon {
    color: rgb(112, 185, 76);
  }

  .courses .icon {
    color: sandybrown;
  }

  .button {
    background-color: #00a6cf;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 11px;
    letter-spacing: 0;
    padding: 5px 7px;
    margin: -1px;
  }
}
</style>