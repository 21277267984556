<template>
  <table class="calendar">
    <thead>
      <tr>
        <th colspan="7">
          <div class="head">
            <span class="cal-button" :class="{ hidden: isPreviousMonth }" v-on:click="prevMonth()">
              <font-awesome-icon icon="chevron-left" class="icon" size="lg"  />
            </span>
            <span class="middle">
              <div class="group">
                <span class="value">{{ formattedYear }}</span><span class="unit">年</span>
              </div>
              <div class="group">
                <span class="value">{{ formattedMonth }}</span><span class="unit">月</span>
              </div>
            </span>
            <span class="cal-button" :class="{ hidden: isNextMonth }" v-on:click="nextMonth()">
              <font-awesome-icon icon="chevron-right" class="icon" size="lg" />
            </span>
          </div>
        </th>
      </tr>
      <tr>
        <th>月</th>
        <th>火</th>
        <th>水</th>
        <th>木</th>
        <th>金</th>
        <th>土</th>
        <th>日</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="week in monthWeeks" :key="week[0].toISOWeekDate()">
        <td v-for="dayDate in week" :key="dayDate.toISOWeekDate()" v-on:click="onDayClicked(dayDate)" class="day-cell" :class="dayClasses(dayDate)">
          <CalendarDay class="day" :date="dayDate" :today="today" :monthDate="monthDate" :courses="augmentedDayCourses(dayDate)" :booked="isDayBooked(dayDate)" :active="isDayActive(dayDate)" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { DateTime } from 'luxon';
import CalendarDay from './CalendarDay.vue';
import { ThenableMap } from '@/data/util'

export default {
  name: 'Calendar',
  components: {
    CalendarDay
  },
  props: {
    calendars: Array,
    today: DateTime,
    monthDate: DateTime,
    bookings: Array,
    student: Object,
    courses: Array
  },
  data() {
    return {
      monthWeeks: [],
      showAll: false
    }
  },
  computed: {
    dayClasses() {
      return date => ({
        active: this.isDayActive(date),
        booked: this.daysBooked.has(date.day),
        otherMonth: date.month !== this.monthDate.month,
        today: date.equals(this.today),
        past: date < this.today
      });
    },
    formattedYear() {
      return this.monthDate.toFormat("yyyy");
    },
    formattedMonth() {
      return this.monthDate.toFormat("LL");
    },
    isPreviousMonth() {
      return this.monthDate.month == this.today.minus({ months: 2 }).month;
    },
    isNextMonth() {
      return this.monthDate.month == this.today.plus({ months: 1 }).month;
    },
    isDayActive() {
      return (dayDate) => !this.isDayEmpty(dayDate);
    },
    isDayEmpty() {
      return (dayDate) => this.augmentedDayCourses(dayDate).length == 0;
    },
    isDayBooked() {
      return (dayDate) => dayDate.month == this.monthDate.month && this.daysBooked.has(dayDate.day);
    },
    coursesById() {
      return new ThenableMap(this.courses, it => it.id);
    },
    courseById() {
      return courseId => this.coursesById.find(courseId);
    },

    // - Sets
    daysBooked() {
      return new Set(this.bookings.map(it => it.day));
    },
    studentCourses() {
      return new Set(this.student.courseIds);
    },
    // - Filtered collections
    specialBookingsCourseIds() {
      const specialBookings = this.bookings.filter(it => !this.studentCourses.has(it.courseId));
      const coursesByDate = specialBookings.reduce((acc, it) => {
        const date = DateTime.local(it.year, it.month, it.day).toISODate();
        date in acc ? acc[date].push(it.courseId) : acc[date] = [it.courseId];
        return acc;
      }, {});
      return dayDate => {
        const isoDate = dayDate.toISODate();
        return isoDate in coursesByDate ? coursesByDate[isoDate] : [];
      }
    },
    dayCourses() {
      return dayDate => this.calendars
          .filter(it => it.year == dayDate.year && it.month == dayDate.month && dayDate.day in it.courses)
          .flatMap(it => it.courses[dayDate.day]);
    },
    filteredDayCourses() {
      return dayDate => this.dayCourses(dayDate)
          .filter(it => this.studentCourses.has(it.course));
    },
    specialBookingsDayCourses() {
      return dayDate => {
        const courseIds = new Set(this.specialBookingsCourseIds(dayDate));
        return this.dayCourses(dayDate)
          .filter(it => courseIds.has(it.course));
      }
    },
    augmentedDayCourses() {
      return dayDate => {
        const courses = this.filteredDayCourses(dayDate)
          .concat(this.specialBookingsDayCourses(dayDate));
        // Should we remove duplicates here?
        return courses.map(it => Object.assign({}, {
          bookingCount: it.bookingCount,
          bookingMax: it.bookingMax
        }, this.courseById(it.course)));
      }
    },
  },
  watch: {
    monthDate() {
      this.genMonth();
    }
  },
  beforeMount() {
    this.genMonth();
  },
  methods: {
    /* - SECTION: calendar generation */

    prevMonth() {
      if (this.isPreviousMonth) return;
      const prevMonth = this.monthDate.minus({ months: 1 });
      this.$emit('monthSelected', prevMonth);
    },

    nextMonth() {
      if (this.isNextMonth) return;
      const nextMonth = this.monthDate.plus({ months: 1 });
      this.$emit('monthSelected', nextMonth);
    },

    /**
     * Generates month object used for rendering the calendar component.
     * @year What year to generate calendar for, ex. 2018
     * @month What month [1-12] to generate calendar for, ex. 5 for May
     */
    genMonth() {
      const monthWeeks = [];
      // Weekdays are 1-7 for Monday to Sunday
      const daysAfterMonday = this.monthDate.weekday - 1;
      let firstDayOfWeek = this.monthDate.minus({ days: daysAfterMonday });
      do {
        const week = [];
        for (let i = 0; i < 7; ++i) {
          const day = firstDayOfWeek.plus({ days: i });
          week.push(day);
        }
        monthWeeks.push(week);
        firstDayOfWeek = firstDayOfWeek.plus({ days: 7 })
      } while (firstDayOfWeek.month == this.monthDate.month);
      
      this.monthWeeks = monthWeeks;
    },

    /* - SECTION: event handlers */

    onDayClicked(weekDate) {
        if (!this.isDayActive(weekDate)) return;
      this.$emit("daySelected", weekDate);
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin toggle($size: 36px, $color: rgba(19, 191, 17, 1)) {
  .toggle, .toggle:active {
    position: absolute;
    top: -5000px;
    height: 0;
    width: 0;
    opacity: 0;
    border: none;
    outline: none;

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: .25s ease-in-out;
    outline: none;

    &:checked {
      + label {
        box-shadow: inset 0 0 0 18px $color, 0 0 0 2px $color;
      }
      + label:before {
        left: calc(100% - #{$size});
        box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0,0,0,.3);
      }
    }

    + label {
      display: inline-block;
      position: relative;
      padding: 10px;
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 18px;
      background: #f8f8f8;
      cursor: pointer;
      width: calc(2 * #{$size});
      height: $size;
      
      box-shadow: inset 0 0 0 0px rgb$color, 0 0 0 2px #dddddd;

      box-sizing: border-box;
      margin: 0;
      padding: 0;
      transition: .25s ease-in-out;
      outline: none;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        line-height: calc(#{$size} - 2px);
        text-indent: calc(#{$size} + 4px);
        height: $size;
        width: $size;
        border-radius: 100%;
        top: 0px;
        left: 0px;
        right: auto;
        background: white;
        box-shadow: 0 3px 3px rgba(0, 0, 0, .2), 0 0 0 2px #dddddd;

        box-sizing: border-box;
        margin: 0;
        padding: 0;
        transition: .25s ease-in-out;
        outline: none;
      }

      &:after {
        color: white;
        content: attr(data-content);
        line-height: $size;
        margin-right: 8px;
        position: absolute;
        right: 2 * $size;
        white-space: nowrap;
      }
    }
  }
}

table.calendar {
  background-color: #00a6cf;
  border-radius: 8px;
  // border-collapse: collapse;
  border-spacing: 1px;
  // border-spacing: 0.5rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 12px;
  width: 100%;

  thead {
    tr:first-of-type {
      th {
        padding-bottom: 6px;
        text-align: center;
      }
    }
    tr:last-of-type {
      th {
        background-color: #eee;
        font-weight: normal;
        padding: 4px 0;
        text-align: center;

        &:nth-last-of-type(1) {
          background-color: #f7c7cd;
        }

        &:nth-last-of-type(2) {
          background-color: #cee2e9;
        }
      }
    }
  }

  .head {
    display: flex;

    .cal-button,
    .middle {
      background-color: #ffd93b;
    }

    .middle {
      flex: 1;
      margin: 0 2px;
      padding: 6px;
      
      .group {
        display: inline-block;

        .value {
          font-size: 18px;
          font-weight: bold;
        }

        .unit {
          font-size: 12px;
          font-weight: bold;

          &:first-of-type {
            margin-right: 10px;
          }
        }

        &:first-of-type {
          margin-right: 6px;
        }
      }
    }

    .cal-button, .cal-button:visited {
      color: black;
      display: inline-block;
      width: 21%;
    }

    .cal-button:not(.hidden):hover, .cal-button:focus {
      background-color: #dab831;
      cursor: pointer;
    }

    .cal-button.hidden .icon {
      visibility: hidden;
    }

    .cal-button:first-of-type {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      padding-left: 8px;
      margin-left: -1px;
    }

    .cal-button:last-of-type {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      padding-right: 8px;
      margin-right: -1px;
    }
  }

  .day-cell {
    // border: 2px solid rgba(0, 0, 0, 0.6);
    background-color: white;
    cursor: default;
    padding: 0;
    vertical-align: top;
    width: calc(100% / 7);

    &.otherMonth {
      background-color: rgb(231, 231, 231);
    }
    &.today {
      background-color: lightgoldenrodyellow;
    }
    &.active {
      cursor: pointer;
      // &:hover {
      //   background-color: rgb(209, 237, 245);
      //   background-color: rgba(5, 154, 212, 0.15);
      // }
    }
  }

  tfoot td {
    text-align: center;
    color: #fafafa;
    padding-top: 11px;
  }
}

.events {
  margin-bottom: 20px;
}

.day {
  min-height: 52px;
  width: 100%;
}

.past {
  color: #a3a3a3;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


</style>