import { setLineAuthHeader, setClientTokenAuthHeader, clearAuthHeader } from '@/axios'
import Vue from 'vue'
import axios from 'axios'
import './jquery-global'
import Loading from 'vue-loading-overlay'
import App from './App.vue'
import router from './router'
import { VueFontAwesome } from './fontawesome'
import { store, repo } from '@/store'

import 'vue-loading-overlay/dist/vue-loading.css'

VueFontAwesome.init();

Vue.prototype.$http = axios;
Vue.prototype.$store = store;
Vue.prototype.$repo = repo;

Vue.config.productionTip = false;

Vue.use(Loading);

repo.auth.tryLineAuth();

router.beforeEach((to, from, next) => {
  const isLineAuth = repo.auth.isLineAuth;
  const isAuth = repo.auth.isAuth;

  if (isLineAuth) {
    const token = store.auth.lineId as string;
    setLineAuthHeader(token);
  } else if (isAuth) {
    const token = repo.auth.token as string;
    setClientTokenAuthHeader(token);
  } else {
    clearAuthHeader();
  }

  if (to.name != 'Login' && !isAuth) {
    next({ name: 'Login', query: { redirect: to.fullPath } });
  } else if (to.name == 'Login' && isAuth) {
    next({ path: '/' });
  } else {
    next();
  }
});

// Grab user data if don't have it yet and select active student based on query
// letting only user's students get selected.
router.beforeEach(async (to, from, next) => {
  if (to.name == 'Login' || to.name == 'Logout') { next(); return; }

  const isAuth = repo.auth.isAuth;
  const fetchedUserData = Boolean(store.user.entry);
  if (isAuth) {
    if (!fetchedUserData) {
      try {
        await repo.user.fetch();
        await repo.students.fetch();
      } catch (e: any) {
        if (e?.response?.status === 401) {
          repo.auth.clear();
          next({ name: 'Login', query: { redirect: to.fullPath } });
        } else {
          next(e);
        }
        return;
      }
    }

    const studentId = to.query.studentId;
    const students = store.students.entries;
    const selectedStudent = students.length > 0 ? students.find(it => it.id === studentId) || students[0] : null;
    repo.students.select(selectedStudent);
  }

  next();
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
