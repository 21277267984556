<template>
  <div class="day" :class="classes">
    <div class="date">
      <span>{{ date.day }}</span>
      <font-awesome-icon v-show="booked" icon="flag" class="icon" size="xs" />
    </div>
    <div class="tags-container">
      <CalendarBadge v-for="course in sortedCourses" :key="course.id" class="badge" :course="course" :date="date" :today="today" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { compareCoursesOrderFn } from '@/data/Courses'
import CalendarBadge from './CalendarBadge.vue'

export default {
  name: 'CalendarDay',
  components: {
    CalendarBadge
  },
  props: {
    active: Boolean,
    booked: Boolean,
    date: DateTime,
    today: DateTime,
    monthDate: DateTime,
    courses: Array
  },
  computed: {
    classes() {
      return {
        active: this.active,
        booked: this.booked,
        otherMonth: this.date.month !== this.monthDate.month,
        today: this.date.equals(this.today),
        past: this.date < this.today
      };
    },
    sortedCourses() {
      const courses = this.courses.slice(); // need to make a copy as sorting in js is in-place
      courses.sort(compareCoursesOrderFn);
      return courses;
    }
  }
}
</script>

<style lang="scss" scoped>
.day {
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  letter-spacing: 0;
  position: relative;

  &.booked {
    background-image: url('../../assets/booked-day-bg.png');
  }

  &.active:hover {
    background: rgb(209, 237, 245);
  }

  .date {
    display: inline-block;
    font-size: 12px;
    padding: 2px 0;
    position: relative;

    .icon {
      color: #ff8b50;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -1px;
      margin-right: -1px;
      transform: translateX(100%) translateY(-50%);
    }
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1px;
    position: relative;
    text-align: center;
  }

  .badge {
    margin-right: 1px;
    margin-bottom: 1px;
  }

  &.otherMonth {
    color: #a3a3a3;
  }
  &.today {
    background-color: rgb(250, 250, 210);
  }
}
</style>