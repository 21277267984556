import Vue from 'vue';
import { fetch, findById } from '@/api/client/Students';
import { predicateStudentIsActive } from '@/data/Students';

export const store = Vue.observable({
  entries: [] as Student[],
  selected: null as Student | null
});

export class StudentsRepo {
  public async fetch() {
    const students = await fetch();
    const activeStudents = students.filter(predicateStudentIsActive);
    store.entries = activeStudents;
    // Reselect student by id from new data.
    if (store.selected) {
      store.selected = store.entries.find(it => it.id === store.selected?.id) ?? null;
    }
  }

  public async fetchById(studentId: string) {
    const student = await findById(studentId);
    store.entries = store.entries.filter(it => it.id !== student.id);
    if (predicateStudentIsActive(student)) {
      store.entries = store.entries.concat(student);
    }
    // Reselect student by id from new data.
    if (store.selected) {
      store.selected = store.entries.find(it => it.id === store.selected?.id) ?? null;
    }
  }

  public clear() {
    store.entries = [];
  }

  public select(student: Student | null) {
    store.selected = student;
  }

  public clearSelection() {
    store.selected = null;
  }
}
