import axios from '@/axios';
import { apiUrl } from './';

const baseUrl = `${apiUrl}/calendar`;

export async function fetch(studentId: string, year: number, month: number): Promise<Calendar> {
  const url = `${baseUrl}/${year}/${month}`;
  const params = {
    studentId
  };
  const res = await axios.get(url, { params });
  return res.data as Calendar;
}
