import axios from '@/axios';
import { apiUrl } from './';

const baseUrl = `${apiUrl}/users`;

export async function findByAuth(): Promise<User> {
  const url = `${baseUrl}/auth`;
  const res = await axios.get(url);
  return res.data as User;
}

/**
 * Used by client, userId grabbed from auth, confirm old password.
 */
 export async function changePassword(oldPassword: string, newPassword: string): Promise<void> {
  const url = `${baseUrl}/changePassword`;
  const data = {
    oldPassword,
    newPassword
  };
  await axios.post(url, data);
}

export async function changeLineIDClient(userId: string,token: string): Promise<void> {
  const url = `${baseUrl}/changeLineID`;
  const params = {
    userId
  };
  const data = {
    token
  }
  await axios.post(url, data, { params });
}

export async function getGoogleAuthURL() {
  const url = `${apiUrl}/authGoogle`;
  return (await axios.get(url)).data;
}

export async function getLineConnectUrl(): Promise<string> {
  const res = await axios.get(`${baseUrl}/lineConnectUrl`);
  return res.data as string;
}

export async function disconnectLine() {
  const url = `${baseUrl}/disconnectLine`;
  await axios.post(url);
}

export async function setLineNotifications(active: boolean) {
  const url = `${baseUrl}/setLineNotifications`;
  const data = {
    active
  }
  await axios.post(url, data);
}
