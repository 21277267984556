<template>
  <div class="home">
    <div class="logoContainer">
      <div class="pc">
        <img alt="8x9 logo" src="@/assets/logo.png" class="logo" />
      </div>
      <div class="sp">
        <p>
          <img alt="8x9 logo" src="@/assets/spLogo.png" class="logo" />
          <span>レッスン予約システム</span>
        </p>
      </div>
    </div>
    <div class="container">
      <div class="group">
        <div class="title">
          <font-awesome-icon :icon="'cog'" class="icon" />
          <span>通知設定</span>
        </div>
        <div class="head">
          <div class="text">通知設定</div>
        </div>
        <div class="contents">
          <div class="ui grid">
            <div class="ui row">
              <div class="ui two wide column"></div>
              <div class="ui eight wide column" style="display: flex;">
                <img src="@/assets/ic_line.png" width="24" style="margin-right: 8px;" />
                <span style="line-height: 24px;">LINEで受け取る</span>
              </div>
              <div class="ui six wide column">
                <toggle-button
                  v-model="lineNotificationsActive"
                  :labels="true"
                  name="line"
                  v-on:change="onLineActiveToggled"
                  v-bind:disabled="lineDisabled"
                />
              </div>
            </div>
            <div class="ui row" v-if="lineDisabled">
              <span style="color: gray; font-size: 12px; margin: 0 12px;">
                LINEで通知を受け取るにはLINEと連携が必要です。アカウント設定で接続してください。
              </span>
            </div>
     
            <div class="ui row">
              <div class="ui two wide column"></div>
              <div class="ui eight wide column">
                GoogleCalendarと接続
              </div>
              <div class="ui six wide column">
                <div class="ui basic button">
                  接続済
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="button" v-on:click="onGoogleConnect">
            <font-awesome-icon :icon="'check'" class="icon" />
            Googleカレンダー承認
          </button>

          <button class="button" v-on:click="onApply">
            <font-awesome-icon :icon="'check'" class="icon" />
            確定
          </button> -->
        </div>
        <div class="nav-back" v-on:click="onBack">
          <font-awesome-icon
            :icon="['far', 'arrow-alt-circle-left']"
            class="icon"
          />
          カレンダーに戻る
        </div>
        <div class="ui red toast" id="apply-error">
          <div class="content">
            <div class="ui header">設定は保存されませんでした</div>
            既に保存されているか未入力です。
          </div>
        </div>
        <div class="ui green toast" id="apply-changed">
          <div class="content">
            <div class="ui header">設定を保存しました。</div>
            今後LINEに通知が届きます。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
export default {
  name: "NotificationsPage",
  components: {
    ToggleButton
  },
  props: {
    user: Object,
    value: Boolean,
    labels: Boolean,
    name: String
  },
  data() {
    return {
      lid: "",
      isSwitchDisable: false,
      lineNotificationsActive: false,
      lineID: ""
    };
  },
  computed: {
    lineDisabled() { return !this.user.lineId; }
  },
  beforeMount() {
    this.lineNotificationsActive = !!this.user.extra.lineNotificationsActive;
  },
  methods: {
    onBack() {
      const fromRoute =
        this.fromRoute && this.fromRoute.name
          ? this.fromRoute
          : { name: "Calendar" };
      this.$router.replace(fromRoute);
    },
    async onGoogleConnect() {
      const authurl = await this.$repo.user.getGoogleAuthURL();
      window.location = authurl;
    },
    async onLineActiveToggled() {
      this.$emit('isLoading', true);
      try {
        await this.$repo.user.setLineNotifications(this.lineNotificationsActive);
        await this.$repo.user.fetch();
      } catch (e) {
        this.$emit('networkError', e);
      } finally {
        this.$emit('isLoading', false);
      }
    },
    onApply() {
      //Confirmation of user changes
      if (!this.isSwitchDisable) {
        if (this.switch0) {
          this.$repo.user.changeLineID(this.$store.user.entry.id, this.lineID);
          $("#apply-changed").toast();
        } else {
          this.$repo.user.changeLineID(this.$store.user.entry.id, "");
          $("#apply-error").toast();
        }
      } else if (this.isSwitchDisable) {
        // $("#apply-liff").toast();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.markey-area {
  background-color: red;
  width: 100%;
  height: 3em;
  p {
    padding-top: 0.3em;
    white-space: nowrap;
    text-align: center;
    font-size: 1.5em;
    color: white;
  }
}
.logoContainer {
  .sp {
    display: none !important;
  }
  .pc {
    .logo {
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
      max-width: 35%;
      max-height: 160px;
    }
  }
  @media (max-width: 750px) {
    .pc {
      display: none !important;
    }

    .sp {
      display: block !important;
      p {
        margin: 0;
        padding-bottom: 5px;
      }
      .logo {
        margin: 0 auto;
        max-height: 65px;
        vertical-align: middle;
      }
      span {
        margin-left: 10px;
        font-size: 130%;
        vertical-align: middle;
      }
    }
  }
}

.container {
  justify-content: center;
  display: flex;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;

  .icon {
    color: #00a6cf;
    margin-right: 6px;
  }
}

.head {
  align-items: stretch;
  border: 2px solid #00a6cf;
  border-bottom: none;
  display: flex;

  .text {
    background-color: #00a6cf;
    color: white;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 10px;
  }

  .button {
    align-self: center;
    background-color: #eeeeee;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 10px;
    justify-content: flex-end;
    letter-spacing: 0;
    margin: 4px;
    padding: 4px 8px;

    &:hover {
      background-color: #ccc;
    }
  }
}

.contents {
  // align-items: center;
  border: 2px solid #00a6cf;
  border-top: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 20px 16px;
  text-align: start;
  width: 400px;
  font-weight: 500;

  .row {
    align-items: center;
  }

  .settings {
    list-style: none;
    margin: 0 0 16px 0;
    padding: 0;
    text-align: left;
  }
  label {
    align-items: flex-start;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .basic.button {
    background-color: white;
    // border: none;
    // border-radius: 4px;
    color: #00a6cf;
    cursor: pointer;
    // font-size: 16px;
    font-weight: bold;
    // padding: 2px 30px;
  }
}

.nav-back {
  color: #00a6cf;
  cursor: pointer;
  margin-bottom: 40px;

  &:hover {
    color: #42b983;
  }
}
</style>