import Vue from 'vue';
import { fetch } from '@/api/client/Classrooms';

export const store = Vue.observable({
  entries: [] as Classroom[]
})

export class ClassroomsRepo {
  public async fetch() {
    const classrooms = await fetch();
    store.entries = classrooms;
  }
}
