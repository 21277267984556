import Vue from 'vue';
import { fetch } from '@/api/client/Teachers';

export const store = Vue.observable({
  entries: [] as Teacher[]
})

export class TeachersRepo {
  public async fetch() {
    const teachers = await fetch();
    store.entries = teachers;
  }
}
