import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBookmark, faUserGraduate, faChevronLeft, faChevronRight, faFlag, faUserFriends, 
    faExclamationCircle, faPen, faUserPlus, faUniversity, faClipboard, faExclamationTriangle,
    faInfoCircle,faCheck,faCog, faSignOutAlt, faKey } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as farBookmark, faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'

export const VueFontAwesome = {
    init() {
        library.add(farBookmark, faBookmark, faUserGraduate, faChevronLeft, faChevronRight, faFlag, 
            faUserFriends, faExclamationCircle, faPen, faArrowAltCircleLeft, faUserPlus, faUniversity, 
            faClipboard, faExclamationTriangle, faInfoCircle, faCheck, faCog, faSignOutAlt, faKey );
        
        Vue.component('font-awesome-icon', FontAwesomeIcon);
    }
}