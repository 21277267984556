import Vue from 'vue';
import { fetch, make, remove } from '@/api/client/Bookings';

export const store = Vue.observable({
  entries: [] as Booking[]
})

export class BookingsRepo {
  public async fetch(studentId: string, year: number, month: number) {
    const bookings = await fetch(studentId, year, month);
    store.entries = bookings;
  }

  public async make(studentId: string, lessonId: string, comment: string) {
    await make(studentId, lessonId, comment);
  }

  public async remove(studentId: string, lessonId: string) {
    await remove(studentId, lessonId);
  }

  clear() {
      store.entries = [];
  }
}
