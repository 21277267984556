import axios from '@/axios';
import { apiUrl } from './';

const baseUrl = `${apiUrl}/auth`;

export async function login(email: string, password: string, remember: boolean): Promise<string> {
  const data = {
    email,
    password,
    remember
  };
  const res = await axios.post(baseUrl, data);
  return res.data as string;
}

export async function getLineAuthUrl(): Promise<string> {
  const res = await axios.get(`${apiUrl}/lineAuthUrl`);
  return res.data as string;
}
