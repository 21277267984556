import { DateTime } from 'luxon';
import { arrayCompareFn } from './util'
import { compareCoursesOrderFn } from './Courses'
import { compareTagsNameFn } from './Tags';

export function getMonthId(date: DateTime = DateTime.local().setZone('Asia/Tokyo')): number {
  const monthId = date.year * 12 + (date.month - 1); // luxon returns month as 1-12 whereas we need 0-11 for calculations.
  return monthId;
}

export const gradesOrder = ['小', '中', '高', '不'];

const fullName = (student: Student) => `${student.lastName} ${student.firstName}`;
const fullNamePhonetic = (student: Student) => `${student.lastNameKana} ${student.firstNameKana}`;

export const compareStudentsIdFn = (a: Student, b: Student) => a.id.localeCompare(b.id, undefined, { numeric: true });
export const compareStudentsNameFn = (a: Student, b: Student) => fullName(a).localeCompare(fullName(b), 'ja');
export const compareStudentsNamePhoneticFn = (a: Student, b: Student) => fullNamePhonetic(a).localeCompare(fullNamePhonetic(b), 'ja');
export const compareStudentsCoursesFn = (courseByIdFn: (courseId: string) => Course) =>
  (a: Student, b: Student) => arrayCompareFn(compareCoursesOrderFn,
      a.courseIds.map(it => courseByIdFn(it)).sort(compareCoursesOrderFn),
      b.courseIds.map(it => courseByIdFn(it)).sort(compareCoursesOrderFn));
export const compareStudentsTagsFn = (tagByIdFn: (tagId: number) => Tag) =>
(a: Student, b: Student) => arrayCompareFn(compareTagsNameFn,
    a.tagIds.map(it => tagByIdFn(it)).sort(compareTagsNameFn),
    b.tagIds.map(it => tagByIdFn(it)).sort(compareTagsNameFn));
export const compareStudentsGradeFn = (a: Student, b: Student) => {
  const regex = /[小中高不].＊/;
  if (!a.grade.match(regex) || !b.grade.match(regex)) {
    return a.grade.localeCompare(b.grade, 'ja', { numeric: true });
  }
  const levelA = a.grade ? a.grade[0] : '不';
  const levelB = b.grade ? b.grade[0] : '不';
  const levelCompare = gradesOrder.indexOf(levelA) - gradesOrder.indexOf(levelB);
  if (levelCompare !== 0) return levelCompare;
  const restA = a.grade.substr(1);
  const restB = b.grade.substr(1);
  return restA.localeCompare(restB);
}
export const compareStudentsTicketsAwardedPerMonthFn = (a: Student, b: Student) => a.ticketsAwardedPerMonth - b.ticketsAwardedPerMonth;
export const compareStudentsTicketsAvailableFn = (a: Student, b: Student) => a.ticketsAvailable - b.ticketsAvailable;
export const compareStudentsTicketsCountFn = (a: Student, b: Student) => a.ticketsCount - b.ticketsCount;

export const predicateStudentIsActive = (it: Student) => !it.signOutDate;
export const predicateStudentCoursesInclude = (courseId: string) => (it: Student) => it.courseIds.indexOf(courseId) !== -1;
export const predicateStudentHasTicketAvailable = (it: Student) => it.ticketsAvailable > 0;
