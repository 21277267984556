<template>
  <div class="degree" :class="barClasses">
    <div class="badge" :class="classes" :style="styles">{{ label }}</div>
  </div>
  
</template>

<script>
import { DateTime } from 'luxon'
import { calendarId } from '../../../../common/src/data/Calendar';
export default {
  name: 'CalendarBadge',
  props: {
    course: Object,
    date: DateTime,
    today: DateTime
  },
  computed: {
    label() {
      return this.course.badge.label;
    },
    isPast() {
      return this.date < this.today;
    },
    classes() {
      return {
        past: this.isPast
      }
    },
    barClasses() {
      return {
        past: this.isPast,
        [this.bookingCongestion]: true
      }
    },
    styles() {
      const color = this.course.badge.color;
      return {
        backgroundColor: color,
        borderColor: color
      }
    },
    bookingRatio() {
      if (this.course.bookingMax == 0) return 100;
      return this.course.bookingCount / this.course.bookingMax * 100;
    },
    bookingCongestion() {
      const degree = this.bookingRatio < 70 ? 'congestion-low' : (this.bookingRatio < 100 ? 'congestion-medium' : 'congestion-high');
      return degree;
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/variables';
.degree {
  background-color: none;
  border-radius: 1px;
  font-size:0px;
  padding-bottom: 1.5px;
  &.congestion-low {
    border-bottom: solid 2px #00c853;
  }
  &.congestion-medium {
    border-bottom: solid 2px #FFC400;
  }
  &.congestion-high {
    border-bottom: solid 2px #ff1744;
  }
  &.past {
    border-bottom-color: transparent !important;
  }
  .badge {
    background-color: rgb(180, 180, 180);
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
    font-family: 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック', '游ゴシック体', 'YuGothic', 'Yu Gothic', 'メイリオ', 'Meiryo', 'ＭＳ ゴシック', 'MS Gothic', 'HiraKakuProN-W3', 'TakaoExゴシック', 'TakaoExGothic', 'MotoyaLCedar', 'Droid Sans Japanese', sans-serif;
    font-weight: bold;
    font-size:9.5px;
    line-height: 10px;
    padding: 2px 2px 2px 2px;
    vertical-align: middle;
    white-space: nowrap;

    &.past {
      background-color: rgba(0, 0, 0, 0.3) !important;
      border-color: transparent !important;
    }
  }
}
</style>