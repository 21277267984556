<template>
  <div id="app">
    <loading :active="isLoading" :is-full-page="true" :opacity="0.8">
      <pacman-loader color="rgb(53, 144, 186)"></pacman-loader>
    </loading>
    <loading ref="errorOverlay" :active="isGlobalError" :is-full-page="true" :opacity="0.8">
      <h2 class="ui red icon header">
        <font-awesome-icon icon="exclamation-triangle" class="icon" />
        <div class="content">
          <span class="title">エラー</span>
          <div class="sub header">{{ errorReason }}</div>
          <div class="sub header" v-if="error">{{ errorMessage }}</div>
        </div>
      </h2>
    </loading>
    <loading ref="messageOverlay" :active="isGlobalMessage" :is-full-page="true" :opacity="0.8">
      <h2 class="ui blue icon header">
        <font-awesome-icon icon="info-circle" class="icon" />
        <div class="content">
          <span class="title">メッセージ</span>
          <div class="sub header">{{ message }}</div>
        </div>
      </h2>
    </loading>
    <router-view v-if="ready" v-on:isLoading="isLoading = $event"
      v-on:message="handleMessage($event)"
      v-on:error="handleError($event)"
      v-on:networkError="handleError('Networking error.', $event)" />
    <div v-else>Authenticating and loading students list...</div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue'
import '../semantic/dist/semantic.min'
import '../semantic/dist/semantic.min.css'

export default {
  name: 'App',
  components: {
    Loading,
    PacmanLoader
  },
  data() {
    return {
      ready: false,
      isLoading: false,
      isGlobalError: false,
      isGlobalMessage: false,
      errorReason: '',
      error: null,
      message: '',
      messageCallback: null
    }
  },
  computed: {
    isAuth() { return this.$repo.auth.isAuth; },
    user() { return this.$store.user.entry; },
    errorIsNetworkUnauthorized() { return this.error && this.error.response && this.error.response.status == 401; },
    errorMessage() {
      if (this.errorIsNetworkUnauthorized) return '別の端末でログインされたか長時間操作がなかったためログアウトされました。ログインページに移動します。';
      if (this.error && this.error.response && this.error.response.status == 422) return this.error.response.data.message || this.error.response.data;
      if (this.error && this.error.message) return this.error.message;
      return '';
    },
    errorOverlayElement() {
      return this.$refs.errorOverlay.$el;
    },
    messageOverlayElement() {
      return this.$refs.messageOverlay.$el;
    }
  },
  async created() {
    this.$router.onReady(() => {
      this.ready = true;
    }, () => {
      // In case of error.
      this.ready = true;
    });
    this.$router.onError(e => {
      this.handleError('networkError', e);
    });
  },
  mounted() {
    this.errorOverlayElement.addEventListener('click', this.onErrorOverlayClick);
    this.messageOverlayElement.addEventListener('click', this.onMessageOverlayClick);
  },
  beforeDestroy() {
    this.errorOverlayElement.removeEventListener('click', this.onErrorOverlayClick);
    this.messageOverlayElement.removeEventListener('click', this.onMessageOverlayClick);
  },
  methods: {
    async handleError(reason, error) {
      this.isGlobalError = true;
      this.errorReason = reason;
      this.error = error;

      // Unauthorized -- redirect to login page.
      if (this.errorIsNetworkUnauthorized) {
        window.setTimeout(() => {
          this.$repo.auth.clear();
          this.$router.push({ name: 'Login', query: { redirect: this.$route.fullPath } });
          this.clearError();
        }, 3000);
      }
    },
    handleMessage({ message, callback }) {
      this.isGlobalMessage = true;
      this.message = message;
      this.messageCallback = callback;
    },
    onErrorOverlayClick() {
      this.clearError();
    },
    onMessageOverlayClick() {
      this.clearMessage();
      this.messageCallback();
      this.messageCallback = null;
    },
    clearError() {
      this.isGlobalError = false;
      this.errorReason = '';
      this.error = null;
    },
    clearMessage() {
      this.isGlobalMessage = false;
      this.message = '';
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.ui.icon.header {
  .icon {
    font-size: 48px;
  }

  &.red .icon {
    color: #ff8b50;
  }

  &.blue .icon {
    color: #00a6cf;
  }

  .title {
    display: inline-block;
    margin-bottom: 10px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
