import Vue from 'vue'
import { fetch } from '@/api/client/Calendar'

export const store = Vue.observable({
    //
});

export class CalendarRepo {
    async fetch(studentId: string, year: number, month: number): Promise<Calendar> {
        const calendar = await fetch(studentId, year, month);
        return calendar;
    }
}
