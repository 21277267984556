import axios from '@/axios';
import { apiUrl } from './';

const baseUrl = `${apiUrl}/bookings`;

/**
 * GET /bookings/{year}/{month}
 */
export async function fetch(studentId: string, year: number, month: number): Promise<Booking[]> {
  const url = `${baseUrl}/${year}/${month}`;
  const params = {
    studentId
  }
  const res = await axios.get(url, { params });
  return res.data as Booking[];
}

/**
 * POST /bookings
 */
export async function make(studentId: string, lessonId: string, comment: string) {
  const data = {
    lessonId,
    studentId,
    comment
  };
  await axios.post(baseUrl, data);
}

/**
 * DELETE /bookings/{lessonId}/{studentId}
 */
export async function remove(studentId: string, lessonId: string) {
  const url = `${baseUrl}/${lessonId}/${studentId}`;
  await axios.delete(url);
}
