import Vue from 'vue';
import { findByAuth, changePassword , changeLineIDClient, getGoogleAuthURL, setLineNotifications, disconnectLine, getLineConnectUrl } from '@/api/client/Users';

export const store = Vue.observable({
  entry: null as User | null
});

export class UserRepo {
  public async fetch(): Promise<void> {
    const user = await findByAuth();
    store.entry = user;
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await changePassword(oldPassword, newPassword);
  }

  public async changeLineID(userId: string, token: string): Promise<void> {
    await changeLineIDClient(userId, token)
  }

  public async getGoogleAuthURL() {
    return await getGoogleAuthURL();    
  }

  public async getLineConnectUrl() {
    const url = await getLineConnectUrl();
    return url;
  }

  public async disconnectLine() {
    return await disconnectLine();
  }

  public async setLineNotifications(active: boolean) {
    return await setLineNotifications(active);
  }

  public clear() {
    store.entry = null;
  }
}
