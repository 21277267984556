import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import { DateTime } from 'luxon';
import Calendar from '../pages/Calendar.vue'
import Login from '../pages/Login.vue'
import Logout from '../pages/Logout.vue'
import AccountSettings from '../pages/AccountSettings.vue'
import SwitchUser from '../pages/SwitchUser.vue'
import Notifications from '../pages/Notifications.vue'
import { store } from '@/store'

Vue.use(VueRouter)

const getYearMonth = (route: Route) => {
  const now = DateTime.local();
  const year = route.query.year ? parseInt(route.query.year as string) : now.year;
  const month = route.query.month ? parseInt(route.query.month as string) : now.month;
  return { year, month };
}

const getDate = (route: Route) => {
  const date = route.params.date ? DateTime.fromISO(route.params.date) : DateTime.local();
  return date;
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Calendar',
    component: Calendar,
    props: (route: Route) => {
      const student = store.students.selected;
      const { year, month } = getYearMonth(route);
      return { student, year, month }
    },
    beforeEnter: (to, from, next) => {
      // Prevent navigating to months before current - 2 and after current + 1.
      // Redirect to current month if such navigation was requested.
      const { year, month } = getYearMonth(to);
      const monthDate = DateTime.local(year, month);
      const now = DateTime.local();
      const currentMonthDate = DateTime.local(now.year, now.month);
      const monthsDiff = monthDate.diff(currentMonthDate, 'months').months;
      if (monthsDiff < -2 || monthsDiff > 1) {
        next({ name: to.name!, query: {} });
        return;
      }
      next();
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: (route: Route) => {
      const redirectUrl = route.query.redirect || '/';
      return { redirectUrl }
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    props: (route: Route) => {
      const user = store.user.entry;
      return { user };
    }
  },
  {
    path: '/switch-user',
    name: 'SwitchUser',
    component: SwitchUser,
    props: (route: Route) => {
      const user = store.user.entry;
      const student = store.students.selected;
      const students = store.students.entries;
      return { user, student, students };
    }
  },
  {
    path: '/settings/notifications',
    name: 'Notifications',
    component: Notifications,
    props: (route: Route) => {
      const user = store.user.entry;
      return { user };
    }
  },
  {
    path: '/booking/:date',
    name: 'Booking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "booking" */ '../pages/Booking.vue'),
    props: (route: Route) => {
      const user = store.user.entry;
      const student = store.students.selected;
      const date = getDate(route);
      return { user, student, date };
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
