import Vue from 'vue';
import { login, getLineAuthUrl } from '@/api/client/Auth';

export const store = Vue.observable({
  lineId: null as string | null
})

const storageTokenKey = 'authToken';

export class AuthRepo {
  get token(): string | null { return window.sessionStorage.getItem(storageTokenKey) || window.localStorage.getItem(storageTokenKey); }
  get isAuth() { return Boolean(this.token); }
  get isLineAuth() { return Boolean(store.lineId); }

  public async login(email: string, password: string, rememberMe: boolean): Promise<string> {
    const authToken = await login(email, password, rememberMe);
    return authToken;
  }

  public async getLineAuthUrl() {
    const url = await getLineAuthUrl();
    return url;
  }

  public async setToken(token: string, remember = false) {
    this.clear();
    if (remember) {
      window.localStorage.setItem(storageTokenKey, token);
    } else {
      window.sessionStorage.setItem(storageTokenKey, token);
    }
  }

  public async clear() {
    window.sessionStorage.removeItem(storageTokenKey);
    window.localStorage.removeItem(storageTokenKey);
  }

  public async tryLineAuth() {
    // store.lineId = 'test';
  }
}
