<template>
  <div class="home">
    <div class="logoContainer">
      <div class="pc">
        <img alt="8x9 logo" src="@/assets/logo.png" class="logo">
      </div>
      <div class="sp">
        <p>
          <img alt="8x9 logo" src="@/assets/spLogo.png" class="logo">
          <span>レッスン予約システム</span>
        </p> 
      </div>  
    </div>
    <div class="container">
      <div class="title">
        <font-awesome-icon :icon="'key'" class="icon" />
        <span>アカウント設定</span>
      </div>
      <div class="group">
        <div class="head">
          <div class="text">パスワード変更</div>
        </div>
        <div class="contents">
          <label>
            現在のパスワード
            <input type="password" v-model="currentPassword" />
          </label>
          <label>
            新しいパスワード
            <input type="password" v-model="newPassword" />
          </label>
          <label>
            新しいパスワードの確認
            <input type="password" v-model="newPasswordConfirm" />
          </label>
          <div class="button" v-on:click="onConfirm">
            <font-awesome-icon :icon="'check'" class="icon" />
            確定
          </div>
        </div>
      </div>
      <div class="group">
        <div class="head">
          <div class="text">外部サービス連携</div>
        </div>
        <div class="contents" style="display: flex; flex-direction: row;">
          <img src="@/assets/ic_line.png" width="24" style="margin-right: 8px;" />
          <span style="line-height: 24px; margin-right: 8px;">LINE</span>
          <span v-if="lineConnected" style="margin-right: 8px;">連携済み</span>
          <div class="button" v-if="!lineConnected" v-on:click="onConnectLine">
            Connect
          </div>
          <div class="button" v-else v-on:click="onDisconnectLine">
            Disconnect
          </div>
        </div>
        <div class="nav-back" v-on:click="onBack">
          <font-awesome-icon :icon="['far', 'arrow-alt-circle-left']" class="icon" />
          カレンダーに戻る
        </div>
      </div>
    </div>
    <div class="ui green toast" id="line-connect-success">
      <div class="content">
        LINEに連携することに成功しました。
      </div>
    </div>
    <div class="ui red toast" id="line-connect-error">
      <div class="content">
        LINEに連携することに失敗しました。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSettingsPage',
  props: {
    user: Object
  },
  data() {
    return {
      fromRoute: null,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  },
  computed: {
    lineConnected() { return this.user && this.user.lineId; },
    queryParams() {
      const uri = window.location.search.substring(1);
      return new URLSearchParams(uri);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => { vm.fromRoute = from; });
  },
  mounted() {
    const isLineConnectCallback = this.queryParams.get("line_connect");
    if (isLineConnectCallback) {
      this.handleLineConnectCallback();
    }
  },
  methods: {
    async onConfirm() {
      this.$emit('isLoading', true);
      try {
        if (this.newPassword !== this.newPasswordConfirm) throw { response: { data: "Passwords don't match." } };

        await this.$repo.user.changePassword(this.currentPassword, this.newPassword);

        this.$emit('isLoading', false);

        this.$emit('message', { message: 'パスワードを変更しました。', callback: () => {
          this.navigateBack();
        }});
      } catch (e) {
        this.$emit('isLoading', false);
        if (e.response.data === 'Wrong password.') {
          this.$emit('error', '現在のパスワードが違っています。');
        } else if (e.response.data === "Passwords don't match.") {
          this.$emit('error', '確認パスワードが違っています。');
        } else {
          this.$emit('networkError', e);
        }
      }
    },
    async onConnectLine() {
      const url = await this.$repo.user.getLineConnectUrl();
      window.location = url;
    },
    async handleLineConnectCallback() {
      this.$emit('isLoading', true);
      try {
        const params = this.queryParams;
        const result = params.get("line_connect");
        if (result == 'success') {
          params.delete('line_connect');

          let paramsStr = params.toString();
          paramsStr = paramsStr ? `?${paramsStr}` : '';
          const pathQuery = window.location.pathname + paramsStr;
          history.pushState(null, '', pathQuery);
          
          $("#line-connect-success").toast();
        } else if (result == 'error') {
          // const error = params.get("error");

          params.delete('line_connect');
          params.delete('error');
          
          let paramsStr = params.toString();
          paramsStr = paramsStr ? `?${paramsStr}` : '';
          const pathQuery = window.location.pathname + paramsStr;
          history.pushState(null, '', pathQuery);

          $("#line-connect-error").toast();
        } else {
          throw 'LINE login callback: wrong state.';
        }
      } catch (e) {
        this.$emit('networkError', e);
      } finally {
        this.$emit('isLoading', false);
      }
    },
    async onDisconnectLine() {
      this.$emit('isLoading', true);
      try {
        await this.$repo.user.disconnectLine();
        await this.$repo.user.fetch();
      } catch (e) {
        this.$emit('networkError', e);
      } finally {
        this.$emit('isLoading', false);
      }
    },
    async onBack() {
      await this.navigateBack();
    },
    async navigateBack() {
      const fromRoute = this.fromRoute && this.fromRoute.name ? this.fromRoute : { name: 'Calendar' };
      await this.$router.replace(fromRoute);
    }
  }
}
</script>

<style lang="scss" scoped>
.logoContainer{
  .sp {
    display: none !important;
  }
  .pc {
    .logo {
      display: block;	
      margin: 0 auto;
      margin-bottom: 10px;
      max-width: 35%;
      max-height: 160px;
    }
  }
  @media (max-width: 750px) {
  
    .pc {
        display: none !important;
    }

    .sp {
      display: block !important;
      p{
        margin: 0;
        padding-bottom: 5px;
      }
      .logo {
        margin: 0 auto;
        max-height: 65px;
        vertical-align: middle;
      }
      span{
        margin-left: 10px;
        font-size: 130%;
        vertical-align: middle;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;

  .icon {
    color: #00a6cf;
    margin-right: 6px;
  }
}

.head {
  align-items: stretch;
  border: 2px solid #00a6cf;
  border-bottom: none;
  display: flex;

  .text {
    background-color: #00a6cf;
    color: white;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 10px;
  }

  .button {
    align-self: center;
    background-color: #eeeeee;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 10px;
    justify-content: flex-end;
    letter-spacing: 0;
    margin: 4px;
    padding: 4px 8px;

    &:hover {
      background-color: #ccc;
    }
  }
}

.contents {
  align-items: center;
  border: 2px solid #00a6cf;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 20px 16px;
  text-align: center;

  label {
    align-items: flex-start;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    input {
      border: 1px solid rgba(0, 166, 207, 0.5);
      font-size: 14px;
      margin-top: 2px;
      padding: 4px 8px;
    }
  }

  .button {
    background-color: #00a6cf;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 30px;
  }
}

.nav-back {
  color: #00a6cf;
  cursor: pointer;
  margin-bottom: 40px;

  &:hover {
    color: #42b983;
  }
}
</style>