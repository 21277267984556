import Vue from 'vue';
import { store as authStore, AuthRepo } from '@/store/AuthRepo';
import { store as bookingsStore, BookingsRepo } from '@/store/BookingsRepo';
import { store as calendarStore, CalendarRepo } from '@/store/CalendarRepo';
import { store as classroomsStore, ClassroomsRepo } from '@/store/ClassroomsRepo';
import { store as coursesStore, CoursesRepo } from '@/store/CoursesRepo';
import { store as curriculumsStore, CurriculumsRepo } from '@/store/CurriculumsRepo';
import { store as lessonsStore, LessonsRepo } from '@/store/LessonsRepo';
import { store as studentsStore, StudentsRepo } from '@/store/StudentsRepo';
import { store as teachersStore, TeachersRepo } from '@/store/TeachersRepo';
import { store as userStore, UserRepo } from '@/store/UserRepo';

export type RepoType = {
  auth: AuthRepo;
  bookings: BookingsRepo;
  calendar: CalendarRepo;
  classrooms: ClassroomsRepo;
  courses: CoursesRepo;
  curriculums: CurriculumsRepo;
  lessons: LessonsRepo;
  students: StudentsRepo;
  teachers: TeachersRepo;
  user: UserRepo;
};

export const repo: RepoType = {
  auth: new AuthRepo(),
  bookings: new BookingsRepo(),
  calendar: new CalendarRepo(),
  classrooms: new ClassroomsRepo(),
  courses: new CoursesRepo(),
  curriculums: new CurriculumsRepo(),
  lessons: new LessonsRepo(),
  students: new StudentsRepo(),
  teachers: new TeachersRepo(),
  user: new UserRepo()
}

export const store = Vue.observable({
  auth: authStore,
  bookings: bookingsStore,
  calendar: calendarStore,
  classrooms: classroomsStore,
  courses: coursesStore,
  curriculums: curriculumsStore,
  lessons: lessonsStore,
  students: studentsStore,
  teachers: teachersStore,
  user: userStore
})
