<template>
  <div>
    <div id="container">
      <div class="pc">
        <UserWidget :student="student" :bookings="bookings" class="top-widget" />
      </div>
      <div class="sp">
        <SpUserWidget :student="student" :bookings="bookings" class="top-widget" />    
      </div>  
      <Calendar :today="today" :monthDate="monthDate" :calendars="calendars" :courses="courses" :bookings="bookings" :student="student"
      v-on:daySelected="onDaySelected" v-on:monthSelected="onMonthSelected" />
    </div>
    <div class="footer">
      <a v-on:click="settings">
        <font-awesome-icon :icon="'cog'" class="icon" />
        通知設定
      </a> | 
      <a v-on:click="goToAccountSettings">
        <font-awesome-icon :icon="'key'" class="icon" />
        アカウント設定
      </a> | 
      <a v-on:click="logout">
        <font-awesome-icon :icon="'sign-out-alt'" class="icon" />
        ログアウト
      </a>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
// @ is an alias to /src
import UserWidget from '@/components/UserWidget.vue'
import SpUserWidget from '@/components/SpUserWidget.vue'
import Calendar from '@/components/calendar/Calendar.vue'

export default {
  name: 'CalendarPage',
  components: {
    UserWidget,
    SpUserWidget,
    Calendar
  },
  props: {
    student: Object,
    year: Number,
    month: Number,
  },
  data() {
    return {
      calendars: []
    }
  },
  computed: {
    today() {
      return DateTime.local().startOf('day');
    },
    monthDate() {
      return DateTime.local(this.year, this.month);
    },
    bookings() {
      return this.$store.bookings.entries;
    },
    courses() {
      return this.$store.courses.entries;
    }
  },
  watch: {
    async $route(to, from) {
      this.$emit('isLoading', true);
      await this.init();
      this.$emit('isLoading', false);
    }
  },
  async beforeMount() {
    this.$emit('isLoading', true);
    await this.init();
    this.$emit('isLoading', false);
  },
  methods: {
    async init() {
      this.$repo.bookings.clear();
      // try {
        // Awaiting here does not prevent the component continuing to load.
        const coursesPromise = this.$repo.courses.fetch();
        const fetchPromise = this.fetchData();
        await Promise.all([coursesPromise, fetchPromise]);
      // } catch (e) {
      //   this.$emit('networkError', e);
      // }
    },
    async fetchData() {
      if (!this.student) return;
      const calendarPromises = [this.$repo.calendar.fetch(this.student.id, this.year, this.month)];
      const calendarPromise = Promise.all(calendarPromises).then(list => this.calendars = list.flatMap(it => it).filter(it => it) /* Remove null values (calendars not found). */);
      const bookingsPromise = this.$repo.bookings.fetch(this.student.id, this.year, this.month);
      await Promise.all([calendarPromise, bookingsPromise]);
    },
    /**
     * @param {DateTime} monthDate
     */
    async onMonthSelected(monthDate) {
      await this.$router.replace({ query: Object.assign({}, this.$route.query, { year: monthDate.year, month: monthDate.month })});
    },
    /**
     * @param {DateTime} dayDate
     */
    async onDaySelected(dayDate) {
      const date = dayDate.toISODate();
      await this.$router.push({ name: 'Booking', params: { date }, query: this.$route.query });
    },
    async goToAccountSettings() {
      await this.$router.push({ name: 'AccountSettings', query: this.$route.query });
    },
    async settings() {
      await this.$router.push({ name: 'Notifications', query: this.$route.query });
    },
    async logout() {
      await this.$router.push({ name: 'Logout' });
    }
  }
}
</script>

<style lang="scss" scoped>
.top-widget {
  margin-bottom: 10px;
}

.footer {
  color: lightgray;
  padding: 20px 0;
  white-space: nowrap;
  a {
    color: gray;
    cursor: pointer;
    font-size: 12px;
    margin: 0 10px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (min-width: 750px) {
  #container {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.sp {
    display: none !important;
}
@media (max-width: 750px) {
    .pc {
        display: none !important;
    }
    .sp {
        display: block !important;
    }
}
</style>