import axios from 'axios'

const axiosInstance = axios.create();

export const setTokenAuthHeader = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
}

export const setClientTokenAuthHeader = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `ClientToken ${token}`;
}

export const setTeacherTokenAuthHeader = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `TeacherToken ${token}`;
}

export const setLineAuthHeader = (lineId: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `LINE ${lineId}`;
}

export const clearAuthHeader = () => {
  delete axiosInstance.defaults.headers.common['Authorization'];
}

export default axiosInstance;