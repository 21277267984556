import Vue from 'vue';
import { fetch } from '@/api/client/Courses';

export const store = Vue.observable({
  entries: [] as Course[]
})

export class CoursesRepo {
  public async fetch() {
    const courses = await fetch();
    store.entries = courses;
  }
}
