export const timeToNumber = (time: string) => {
  const parts = time.split(':');
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  return hours * 60 + minutes;
}

export const compoundCompareFn = <T> (...compareFns: Array<(a: T, b: T) => number>) => {
  return (a: T, b: T) => {
    for (const compareFn of compareFns) {
      const result = compareFn(a, b);
      if (result !== 0) return result;
    }
    return 0;
  }
}

export const arrayCompareFn = <T> (sortFn: (a: T, b: T) => number, as: T[], bs: T[]): number => {
  if (as.length > bs.length) return 1;
  if (bs.length > as.length) return -1;
  if (as.length == 0) return 0;
  const a = as[0];
  const b = bs[0];
  const order = sortFn(a, b);
  // Possibly compare next element in array if order == 0 here.
  if (order == 0) {
    const asp = as.slice(1);
    const bsp = bs.slice(1);
    return arrayCompareFn(sortFn, asp, bsp);
  }
  return order;
}

export class ThenableMap<K, V> {
  map: Map<K, V>;

  constructor(list: V[], idFn: (value: V) => K) {
    this.map = new Map(list.map(it => [idFn(it), it]));
  }

  find<T>(id: K, mapFn: (value: V) => T = (it => it as unknown as T)): T | null {
    return this.map.has(id) ? mapFn(this.map.get(id) as V /* needed because of type checking failure /does not recognise .has has already ruled out value being undefined/ */) : null;
  }

  has(id: K): boolean {
    return this.map.has(id);
  }
}