import axios from '@/axios';
import { apiUrl } from './';

const baseUrl = `${apiUrl}/students`;

export async function findById(studentId: string): Promise<Student> {
  const url = `${baseUrl}/${encodeURIComponent(studentId)}`;
  const res = await axios.get(url);
  return res.data as Student;
}

export async function fetch(): Promise<Student[]> {
  const res = await axios.get(baseUrl);
  return res.data as Student[];
}
