import Vue from 'vue';
import { fetch } from '@/api/client/Curriculums';

export const store = Vue.observable({
    entries: [] as Curriculum[]
});

export class CurriculumsRepo {
    public async fetch() {
        const curriculums = await fetch();
        store.entries = curriculums;
    }
}
