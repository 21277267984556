var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":'cog'}}),_c('span',[_vm._v("通知設定")])],1),_vm._m(1),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"ui grid"},[_c('div',{staticClass:"ui row"},[_c('div',{staticClass:"ui two wide column"}),_vm._m(2),_c('div',{staticClass:"ui six wide column"},[_c('toggle-button',{attrs:{"labels":true,"name":"line","disabled":_vm.lineDisabled},on:{"change":_vm.onLineActiveToggled},model:{value:(_vm.lineNotificationsActive),callback:function ($$v) {_vm.lineNotificationsActive=$$v},expression:"lineNotificationsActive"}})],1)]),(_vm.lineDisabled)?_c('div',{staticClass:"ui row"},[_c('span',{staticStyle:{"color":"gray","font-size":"12px","margin":"0 12px"}},[_vm._v(" LINEで通知を受け取るにはLINEと連携が必要です。アカウント設定で接続してください。 ")])]):_vm._e(),_vm._m(3)])]),_c('div',{staticClass:"nav-back",on:{"click":_vm.onBack}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'arrow-alt-circle-left']}}),_vm._v(" カレンダーに戻る ")],1),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logoContainer"},[_c('div',{staticClass:"pc"},[_c('img',{staticClass:"logo",attrs:{"alt":"8x9 logo","src":require("@/assets/logo.png")}})]),_c('div',{staticClass:"sp"},[_c('p',[_c('img',{staticClass:"logo",attrs:{"alt":"8x9 logo","src":require("@/assets/spLogo.png")}}),_c('span',[_vm._v("レッスン予約システム")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"text"},[_vm._v("通知設定")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui eight wide column",staticStyle:{"display":"flex"}},[_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"src":require("@/assets/ic_line.png"),"width":"24"}}),_c('span',{staticStyle:{"line-height":"24px"}},[_vm._v("LINEで受け取る")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui row"},[_c('div',{staticClass:"ui two wide column"}),_c('div',{staticClass:"ui eight wide column"},[_vm._v(" GoogleCalendarと接続 ")]),_c('div',{staticClass:"ui six wide column"},[_c('div',{staticClass:"ui basic button"},[_vm._v(" 接続済 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui red toast",attrs:{"id":"apply-error"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"ui header"},[_vm._v("設定は保存されませんでした")]),_vm._v(" 既に保存されているか未入力です。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui green toast",attrs:{"id":"apply-changed"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"ui header"},[_vm._v("設定を保存しました。")]),_vm._v(" 今後LINEに通知が届きます。 ")])])
}]

export { render, staticRenderFns }