<template>
  <div class="page">
    <div class="container">
      <div class="top">
        <p>
          <img alt="8x9 logo" src="@/assets/spLogo.png" class="logo">
          <span>レッスン予約システム</span>
        </p>
      </div>
      <div class="field">
        <label>メールアドレス</label>
        <input type="text" placeholder="メールアドレスを入力してください。" v-model="email" v-on:keypress.enter="login">
      </div>
      <div class="field">
        <label>パスワード</label>
        <input type="password" placeholder="パスワードを入力してください。" v-model="password" v-on:keypress.enter="login">
      </div>
      <div class="field">
        <label>
          ログイン情報を保存する
          <input type="checkbox" v-model="rememberMe" v-on:keypress.enter="login">
        </label>
      </div>
      <div class="field">
        <div class="button" v-on:click="login">
          ログイン
          <font-awesome-icon icon="chevron-right" class="icon" size="xs" />
        </div>
      </div>
      <div class="divider-alternative"></div>
      <div class="field">
        <!-- <div class="button" v-on:click="loginLine">
          LINEログイン
          <font-awesome-icon icon="chevron-right" class="icon" size="xs" />
        </div> -->
        <div class="btn-login-line" v-on:click="loginLine"></div>
      </div>
    </div>
    <div class="ui green toast" ref="toast-success"></div>
    <div class="ui red toast" ref="toast-error"></div>
  </div>
</template>

<script>
import { setClientTokenAuthHeader } from '@/axios'

export default {
  name: 'LoginPage',
  props: {
    redirectUrl: String
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false
    }
  },
  computed: {
    queryParams() {
      const uri = window.location.search.substring(1);
      return new URLSearchParams(uri);
    },
  },
  mounted() {
    const isLineLoginCallback = this.queryParams.get("line_login");
    if (isLineLoginCallback) {
      this.handleLineLoginCallback();
    }
  },
  methods: {
    async login() {
      const email = this.email;
      const password = this.password;
      const rememberMe = this.rememberMe;
      this.$emit('isLoading', true);
      try {
        const token = await this.$repo.auth.login(email, password, rememberMe);
        await this.setTokenAndRedirect(token, rememberMe);
      } catch (e) {
        this.handleLoginError(e);
      } finally {
        this.$emit('isLoading', false);
      }
    },
    async loginLine() {
      const url = await this.$repo.auth.getLineAuthUrl();
      window.location = url;
    },
    async handleLineLoginCallback() {
      this.$emit('isLoading', true);
      try {
        const params = this.queryParams;
        const result = params.get("line_login");
        if (result == 'success') {
          const token = params.get("token");

          $(this.$refs['toast-success'])
            .text(`LINE login successful`)
            .toast();

          params.delete('line_login');
          params.delete('token');
          const pathQuery = window.location.pathname + '?' + params.toString();
          history.pushState(null, '', pathQuery);

          await this.setTokenAndRedirect(token);
        } else if (result == 'error') {
          const error = params.get("error");

          $(this.$refs['toast-error'])
            .text(`Error: ${error}`)
            .toast();

          params.delete('line_login');
          params.delete('error');
          const pathQuery = window.location.pathname + '?' + params.toString();
          history.pushState(null, '', pathQuery);
        } else {
          throw 'LINE login callback: wrong state.';
        }
      } catch (e) {
        this.handleLoginError(e);
      } finally {
        this.$emit('isLoading', false);
      }
    },
    async setTokenAndRedirect(token, rememberMe = false) {
      this.$repo.auth.setToken(token, rememberMe);

      setClientTokenAuthHeader(token);

      await this.$repo.user.fetch();
      await this.$repo.students.fetch();

      this.$emit('isLoading', false);

      await this.$router.replace({ path: this.redirectUrl });
    },
    handleLoginError(e) {
      if (e.response.data === 'No active students.') {
        this.$emit('error', 'アクティブな生徒がいません');
      } else {
        this.$emit('networkError', e);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
    margin: auto;
  .logo {
    margin: 0 auto;
    max-height: 100px;
    vertical-align: middle;
  }
  span{
    margin-left: 10px;
    font-size: 130%;
    vertical-align: middle;
  }
}

.page {
  display: inline-block;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;

  label {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.6;
  }

  input {
    font-size: 0.86em;
    line-height: 1.6;
    padding: 0 5px;
  }
}

.divider-alternative {
  border-bottom: 1px solid rgb(211, 211, 211);
  border-bottom-style: dotted;
  margin-bottom: 20px;
  margin-top: 10px;

  &::after {
    background-color: white;
    content: 'or';
    display: table-cell;
    height: 20px;
    left: 50%;
    margin-left: -25px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    width: 50px;
  }
}

.button {
  background-color: lightgray;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  text-align: center;
  transition: background-color 0.5s;

  &:hover {
    background-color: rgb(235, 235, 235);
  }
}

.btn-login-line {
  background-image: url('../assets/btn_login_base.png');
  cursor: pointer;
  width: 110px;
  height: 32px;
  margin: 0 auto;

  &:hover {
    background-image: url('../assets/btn_login_hover.png');
  }

  &:active {
    background-image: url('../assets/btn_login_press.png');
  }
}
</style>