import axios from '@/axios';
import { apiUrl } from './';

const baseUrl = `${apiUrl}/lessons`;

export async function fetch(studentId: string, year: number, month: number, day: number): Promise<Lesson[]> {
  const url = `${baseUrl}/${year}/${month}/${day}`;
  const params = {
    studentId
  };
  const res = await axios.get(url, { params });
  return res.data as Lesson[];
}

export async function findByIds(lessonIds: string[]): Promise<Lesson[]> {
  const params = {
    lessonId: lessonIds
  }
  const res = await axios.get(baseUrl, { params });
  return res.data as Lesson[];
}
