import Vue from 'vue';
import { fetch, findByIds } from '@/api/client/Lessons';

export const store = Vue.observable({
    //
});

export class LessonsRepo {
    async fetch(studentId: string, year: number, month: number, day: number): Promise<Lesson[]> {
        const lessons = await fetch(studentId, year, month, day);
        return lessons;
    }

    async findByIds(lessonIds: string[]): Promise<Lesson[]> {
        const lessons = await findByIds(lessonIds);
        return lessons;
    }
}
