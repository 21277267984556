<template>
  <div>
    Loggin out...
  </div>
</template>

<script>
import { clearAuthHeader } from '@/axios'

export default {
  name: 'LogoutPage',
  mounted() {
    this.$repo.auth.clear();
    
    clearAuthHeader();
    
    this.$router.replace({ path: '/' });
  }
}
</script>

<style lang="scss" scoped>
.ui.form {
  text-align: left;
}

.fields {
  justify-content: center;
}

.invisible {
  visibility: hidden;
}
</style>