<template>
  <div class="home">
    <div class="logoContainer">
      <div class="pc">
        <img alt="8x9 logo" src="@/assets/logo.png" class="logo">
      </div>
      <div class="sp">
        <p>
          <img alt="8x9 logo" src="@/assets/spLogo.png" class="logo">
          <span>レッスン予約システム</span>
        </p> 
      </div>  
    </div>
    <div class="container">
      <div class="group">
        <div class="title">
          <font-awesome-icon :icon="'user-friends'" class="icon" />
          <span>生徒切替</span>
        </div>
        <div class="head">
          <div class="text">生徒リスト</div>
          <div class="button" v-on:click="onConnect" v-if="false">コードを紐付ける</div>
        </div>
        <div class="contents">
          <ul class="users-container">
            <li v-for="student in students" :key="student.id">
              <label>
                <input name="user" type="radio" :value="student" v-model="selectedStudent" />
                {{ student.lastName }} {{ student.firstName }}
              </label>
            </li>
            <li v-if="noStudents">
              <label>生徒を紐付けてください</label>
            </li>
          </ul>
          <div class="button" v-on:click="onSelect" v-if="!noStudents">
            <font-awesome-icon :icon="'check'" class="icon" />
            確定
          </div>
        </div>
        <div class="nav-back" v-on:click="onBack">
          <font-awesome-icon :icon="['far', 'arrow-alt-circle-left']" class="icon" />
          カレンダーに戻る
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchUserPage',
  props: {
    user: Object,
    student: Object,
    students: Array
  },
  data() {
    return {
      fromRoute: null,
      selectedStudent: null
    }
  },
  computed: {
    noStudents() {
      return this.students.length == 0;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => { vm.fromRoute = from; });
  },
  beforeMount() {
    this.selectedStudent = this.student;
  },
  methods: {
    onConnect() {
      // Empty
    },
    onSelect() {
      const studentId = this.selectedStudent.id;
      const fromRoute = this.fromRoute && this.fromRoute.name ? Object.assign({}, this.fromRoute) : { name: 'Calendar' };
      fromRoute.query = Object.assign({}, fromRoute.query, { studentId });
      this.$router.replace(fromRoute);
    },
    onBack() {
      const fromRoute = this.fromRoute && this.fromRoute.name ? this.fromRoute : { name: 'Calendar' };
      this.$router.replace(fromRoute);
    }
  }
}
</script>

<style lang="scss" scoped>
.logoContainer{
  .sp {
    display: none !important;
  }
  .pc {
    .logo {
      display: block;	
      margin: 0 auto;
      margin-bottom: 10px;
      max-width: 35%;
      max-height: 160px;
    }
  }
  @media (max-width: 800px) {
  
    .pc {
        display: none !important;
    }

    .sp {
      display: block !important;
      p{
        margin: 0;
        padding-bottom: 5px;
      }
      .logo {
        margin: 0 auto;
        max-height: 65px;
        vertical-align: middle;
      }
      span{
        margin-left: 10px;
        font-size: 130%;
        vertical-align: middle;
      }
    }
  }
}

.container {
  justify-content: center;
  display: flex;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;

  .icon {
    color: #00a6cf;
    margin-right: 6px;
  }
}

.head {
  align-items: stretch;
  border: 2px solid #00a6cf;
  border-bottom: none;
  display: flex;

  .text {
    background-color: #00a6cf;
    color: white;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 10px;
  }

  .button {
    align-self: center;
    background-color: #eeeeee;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 10px;
    justify-content: flex-end;
    letter-spacing: 0;
    margin: 4px;
    padding: 4px 8px;

    &:hover {
      background-color: #ccc;
    }
  }
}

.contents {
  align-items: center;
  border: 2px solid #00a6cf;
  border-top: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 20px 16px;
  text-align: center;

  .users-container {
    list-style: none;
    margin: 0 0 16px 0;
    padding: 0;
    text-align: left;

    li {
      padding: 2px 0;
    }
  }

  .button {
    background-color: #00a6cf;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 30px;
  }
}

.nav-back {
  color: #00a6cf;
  cursor: pointer;
  margin-bottom: 40px;

  &:hover {
    color: #42b983;
  }
}
</style>